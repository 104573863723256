import React from "react";
import BlogTopMenu from "./BlogTopMenu";
import { constContent } from "staticContent/blog";

const { asideCat, newsletterPageTitle, pageTitle } = constContent;

const BlogContentHeader = ({
  onNavClick,
  onTitleClick,
  content,
  isNewsletter,
}) => (
  <>
    <BlogTopMenu navName={asideCat} onClick={onNavClick}>
      {content}
    </BlogTopMenu>
    <section className="section hero blog">
      <h1
        className="hero__banner-text blog__section-title"
        role="presentation"
        onClick={onTitleClick}
        onKeyDown={onTitleClick}
      >
        {isNewsletter ? newsletterPageTitle : pageTitle}
      </h1>
    </section>
  </>
);

export default BlogContentHeader;
