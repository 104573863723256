const converDateToSlug = (date, slug) => {
  let month = new Date(date).getMonth() + 1;
  if (month < 10) month = '0' + month;
  return (new Date(date).getFullYear() + '/' +  month + '/' + slug).toString();
}

const compilePostsData = (mdxPostsData, wpPostsData) => {
  const mdxPosts = mdxPostsData.map(post => {
    return {
      id: `mdx_${post.id}`,
      title: post.frontmatter.title,
      date: post.frontmatter.date,
      author: post.frontmatter.author,
      tags: post.frontmatter.tags,
      excerptImg: post.frontmatter.excerptImg.childImageSharp,
      excerpt: post.excerpt,
      slug: post.slug
    }
  })

  const wpPosts = wpPostsData.map(post => {
    return {
      id: `wp_${post.id}`,
      title: post.title,
      date: post.date,
      author: post.author.node.name,
      tags: post.categories.nodes.map(cat => cat.name),
      excerptImg: post.featuredImage !== null ? post.featuredImage.node.localFile.childImageSharp : '',
      excerpt: post.excerpt,
      slug: converDateToSlug(post.date, post.slug)
    }
  })

  return [...wpPosts, ...mdxPosts];
}

const getCategoriesList = nodes => {
  const list = [];

  nodes.map(node =>
    node.tags.forEach(category => list.push(category))
  );

  return Array.from(new Set(list));
};

const getArchiveList = (nodes, dateFormat) => {
  const list = [];

  nodes.map(node =>
    list.push(getFormatedDate(node.date, dateFormat))
  );

  return Array.from(new Set(list));
};

const getCategoryPosts = (posts, tag) => {
  const tagPostsArray = [];

  posts.forEach(post => {
    post.tags.filter(
      postTag => postTag === tag && tagPostsArray.push(post)
    );
  });

  return tagPostsArray;
};

const getDatePosts = (posts, date, dateFormat) => {
  const datePosts = [];

  posts.filter(
    post =>
      getFormatedDate(post.date, dateFormat) === date &&
      datePosts.push(post)
  );

  return datePosts;
};

const getFormatedDate = (postDate, options) =>
  new Intl.DateTimeFormat("pl-PL", options).format(new Date(postDate));

const getFormatedDateSneakPeak = (postDate, options) => {
  const formatedDate = new Intl.DateTimeFormat("pl-PL", options).format(
    new Date(postDate)
  );
  return formatedDate[1] === " " ? "0".concat(formatedDate) : formatedDate;
};

const createExcerpt = (content) => {
  let excerpt = content.replace('<p>','').replace('</p>','');
  return (excerpt.length >= 250) ?
    excerpt.substr(0, excerpt.lastIndexOf(' ', 200)) + '...' :
    excerpt;
}

export {
  converDateToSlug,
  compilePostsData,
  getCategoriesList,
  getArchiveList,
  getCategoryPosts,
  getDatePosts,
  getFormatedDate,
  getFormatedDateSneakPeak,
  createExcerpt
};
