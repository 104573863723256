import React from "react";
import BlogSideMenu from "./BlogSideMenu";
import { constContent } from "staticContent/blog";
import { dateOptions } from "constants/blog.constants";
import * as helpers from "helpers/blog.helpers";

const { archiveDate } = dateOptions;
const { getCategoriesList, getArchiveList, getCategoryPosts, getDatePosts } =
  helpers;

const SideMenuType = ({ type, list, getContent }) => {
  if (type === "newsletter") {
    return (
      <BlogSideMenu
        navName={constContent.asideArc}
        after
        onClick={date =>
          getContent(getDatePosts(list, date, archiveDate), false)
        }
      >
        {getArchiveList(list, archiveDate)}
      </BlogSideMenu>
    );
  }

  if (type === "post") {
    return (
      <>
        <BlogSideMenu
          navName={constContent.asideCat}
          after
          onClick={tag =>
            getContent(getCategoryPosts(list, tag), tag !== "Newsletter")
          }
        >
          {getCategoriesList(list)}
        </BlogSideMenu>
        <BlogSideMenu
          navName={constContent.asideArc}
          onClick={date =>
            getContent(getDatePosts(list, date, archiveDate), false)
          }
        >
          {getArchiveList(list, archiveDate)}
        </BlogSideMenu>
      </>
    );
  }

  return false;
};

export default SideMenuType;
