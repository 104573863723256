import React from "react";
import ExcerptBlockContent from "./ExcerptBlockContent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ExcerptBlock = ({ post, onTagClick, showImg }) => {

  const img = post.excerptImg && (
    <GatsbyImage
      image={getImage(post.excerptImg)}
      alt={`${post.title} img`}
    />
  )

  const imgBlock = showImg && (
    <Link to={post.slug} className="blog__article-excerpt-img-link">
      {img}
    </Link>
  );

  return (
    <article className="blog__article">
      <div className={`${showImg ? "blog__article-excerpt-container" : ""}`}>
        {imgBlock}
        <ExcerptBlockContent
          onClick={onTagClick}
          post={post}
          showTags={showImg}
        />
      </div>
    </article>
  );
};

export default ExcerptBlock;
