import React from "react";
import { triangleClass } from "constants/blog.constants";

const BlogSideMenu = ({ children, navName, after, onClick }) => {
  const menuContent = children.map((item, index) => (
    <li key={index} className="blog__aside-menu--item">
      <div
        onClick={() => onClick(item)}
        onKeyDown={() => onClick(item)}
        tabIndex="0"
        role="button"
      >
        {item}
      </div>
    </li>
  ));

  return (
    <>
      <div
        className={`blog__aside-menu ${
          after ? "blog__aside-menu--city-shape" : ""
        }`}
      >
        <ul className="blog__aside-menu--items-list">{menuContent}</ul>
        <p className="blog__aside-menu--title">{navName}</p>
        <div className={`${after ? triangleClass[0] : triangleClass[1]}`}></div>
        <div className={after ? "triangle__after--white" : ""}></div>
      </div>
    </>
  );
};

export default BlogSideMenu;
