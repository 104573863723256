import React from "react";
import SideMenuType from "./SideMenuType";

const BlogContentMain = ({ content, isNewsletter, getContent, allPosts }) => (
  <div className="blog-wrapper">
    <section className="blog__articles-wrapper">
      {content}
    </section>
    <aside className="blog__aside-menu-wrapper">
      <SideMenuType
        type={isNewsletter ? "newsletter" : "post"}
        list={allPosts}
        getContent={getContent}
      />
    </aside>
  </div>
);

export default BlogContentMain;
