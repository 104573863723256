import React, { useState } from "react";
import BlogContentHeader from "./BlogContentHeader";
import BlogContentMain from "./BlogContentMain";
import ExcerptBlock from "./ExcerptBlock";
import { getCategoriesList, getCategoryPosts } from "helpers/blog.helpers";
import "styles/components/blog.scss";

const Blog = ({ posts, onlyNewsletter = false }) => {

  const getArticle = (hideNewsletter, post) =>
    (hideNewsletter ? !post.tags.includes("Newsletter") : true) && (
      <ExcerptBlock
        key={post.id}
        post={post}
        onTagClick={tag =>
          getPosts(getCategoryPosts(posts, tag), tag !== "Newsletter")
        }
        showImg={hideNewsletter}
      />
    );

  const getPosts = (posts, hideNewsletter) =>
    setCurrentPosts(getPostsList(posts, hideNewsletter));

  const getPostsList = (posts, hideNewsletter = true) =>
    posts.map(post => getArticle(hideNewsletter, post));

  const [currentPosts, setCurrentPosts] = useState([
    getPostsList(posts, !onlyNewsletter),
  ]);

  return (
    <>
      <BlogContentHeader
        onNavClick={tag =>
          getPosts(getCategoryPosts(posts, tag), tag !== "Newsletter")
        }
        onTitleClick={() => getPosts(posts, !onlyNewsletter)}
        content={getCategoriesList(posts)}
        isNewsletter={onlyNewsletter}
      />
      <BlogContentMain
        content={currentPosts}
        allPosts={posts}
        isNewsletter={onlyNewsletter}
        getContent={getPosts}
      />
    </>
  )
};

export default Blog;
