import React from "react";
import { Link } from "gatsby";
import { constContent } from "staticContent/blog";
import { dateOptions } from "constants/blog.constants";
import { getFormatedDate, createExcerpt } from "helpers/blog.helpers";

const ExcerptBlockContent = ({ onClick, post, showTags }) => {
  const { title, tags, date, author } = post;
  const excerpt = createExcerpt(post.excerpt);

  const getTags = tags =>
    tags.map(tag => (
      <span
        key={tag}
        className="blog__tag"
        tabIndex="0"
        role="button"
        onClick={() => onClick(tag)}
        onKeyDown={() => onClick(tag)}
      >
        {tag}
      </span>
    ));

  const tagsBlock = showTags && (
    <p className="blog__tag-block">
      {constContent.tags}
      {getTags(tags)}
    </p>
  );

  return (
    <div className="blog__article-excerpt">
      <Link to={post.slug}><h2 className="blog__article-title">{title}</h2></Link>
      <div className="blog__article-content" dangerouslySetInnerHTML={{ __html: excerpt }} />
      {tagsBlock}
      <p className="blog__article-captions blog__article-captions--posts-list">
        <span className="blog__article-captions--date">
          {getFormatedDate(date, dateOptions.postsDate)}
        </span>{" "}
        <span className="blog__article-captions--name">{author}</span>
      </p>
    </div>
  );
};

export default ExcerptBlockContent;
