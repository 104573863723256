import React, { useState } from "react";

const BlogTopMenu = ({ children, navName, onClick }) => {
  const [isActive, setIsActive] = useState(false);
  const showBar = isActive ? "active" : "";

  const handleClick = () => setIsActive(!isActive);
  const handleTopMenuClick = item => {
    onClick(item);
    handleClick();
  };

  const navContent = children.map((item, index) => (
    <li key={index} className="blog__top-menu-nav-list-item">
      <div
        onClick={() => handleTopMenuClick(item)}
        onKeyDown={() => handleTopMenuClick(item)}
        tabIndex="0"
        role="button"
      >
        {item}
      </div>
    </li>
  ));

  return (
    <nav className="blog__top-menu">
      <p
        className="blog__top-menu-title"
        role="presentation"
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {navName}
        <span className={`blog__top-menu-title--arrow ${showBar}`}></span>
      </p>
      <ul className={`blog__top-menu-nav-list ${showBar}`}>{navContent}</ul>
    </nav>
  );
};

export default BlogTopMenu;
