const constContent = {
  pageTitle: "Blog HyperView",
  newsletterPageTitle: "Newsletter HyperView",
  btn: {
    search: "szukaj",
    password: "odblokuj",
  },
  asideCat: "Kategorie",
  asideArc: "Archiwum",
  tags: "tagi:",
  passwordInfo: {
    start: "Aby poznać treść kategorii ",
    end: ", musisz podać hasło",
  },
  modal: {
    title: "Nieprawidłowe hasło! 🤷",
    icon: "error",
  },
};

export { constContent };
