const triangleClass = {
  0: "triangle__after",
  1: "triangle__before",
};

const dateOptions = {
  postsDate: {
    month: "long",
    day: "numeric",
    year: "numeric",
  },
  archiveDate: {
    month: "long",
    year: "numeric",
  },
};

export { triangleClass, dateOptions };
